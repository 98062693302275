export default {
  path: 'attendance',
  name: '灵工考勤',
  icon: 'TaskFilled',
  accessKey: '6',
  routes: [
    {
      path: 'day',
      component: '@/pages/attendance/day',
      name: '每日考勤',
      accessKey: '7',
    },
    {
      path: 'day/record',
      component: '@/pages/attendance/day/record',
      name: '补卡记录',
      hideInMenu: true,
      selectedKey: '/attendance/day',
    },
    {
      path: 'staff',
      component: '@/pages/attendance/staff',
      name: '员工考勤',
      accessKey: '8',
    },
    {
      path: 'staff/detail',
      component: '@/pages/attendance/staff/detail',
      name: '详情',
      hideInMenu: true,
      selectedKey: '/attendance/staff',
    },
    {
      path: 'staff/record',
      component: '@/pages/attendance/staff/record',
      name: '调整记录',
      hideInMenu: true,
      selectedKey: '/attendance/staff',
    },
    {
      path: 'employment',
      component: '@/pages/attendance/employment',
      name: '用工考勤',
      accessKey: '9',
    },
    {
      path: 'employment/detail',
      component: '@/pages/attendance/employment/detail',
      name: '详情',
      hideInMenu: true,
      selectedKey: '/attendance/employment',
    },
    {
      path: 'month',
      component: '@/pages/attendance/month',
      name: '月度汇总',
      accessKey: '10',
    },
    {
      path: 'record',
      component: '@/pages/attendance/record',
      name: '打卡记录',
      accessKey: '11',
    },
  ],
};
