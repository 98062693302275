import { parseJson } from '@pangu/utils';
import { useRequest } from '@umijs/max';

import {
  getAppAccountFirstLogin,
  getBasicEnterQuery,
  getBasicInfoAccount,
  getBasicMenuAccount,
} from '@/api';
import { BaseResponse } from '@/interfaces/base';

import type {
  GetAppAccountFirstLoginResponse,
  GetBasicEnterQueryResponse,
  GetBasicInfoAccountResponse,
  GetBasicMenuAccountResponse,
} from '@/api';

export type UserInfo = GetBasicInfoAccountResponse;
export type AccessItem = GetBasicMenuAccountResponse[0];

interface EnterpriseInfo extends Omit<GetBasicEnterQueryResponse, 'bizData'> {
  bizData: {
    address: string;
    businessLicense: string;
    city: string;
    contractPerson: string;
    contractPhone: string;
    externalLogonId: string;
    financeAccountId: string;
    financeAccountName: string;
    latitude: string;
    longitude: string;
    province: string;
    sign: boolean;
    isCanSendToLabor: boolean;
    roomMoney: number;
    companyType?: number;
  };
}

export default function useUserModel() {
  // 用户信息
  const { data: info, run: fetchUserInfo } = useRequest<BaseResponse<UserInfo>>(
    getBasicInfoAccount,
    { manual: true },
  );

  // 用户是否首次登录
  const { data: isFirstLogin, run: fetchUserIsFirstLogin } = useRequest<
    BaseResponse<GetAppAccountFirstLoginResponse>
  >(getAppAccountFirstLogin, { manual: true });

  // 获取企业信息
  const { data: enterpriseInfo, run: fetchEnterpriseInfo } = useRequest<
    BaseResponse<GetBasicEnterQueryResponse>,
    any,
    EnterpriseInfo
  >(getBasicEnterQuery, {
    manual: true,
    formatResult(res) {
      const bizData: any = parseJson(res.data?.bizData, {});
      return {
        ...res.data,
        bizData,
      };
    },
  });

  // 用户权限
  const { data: access = [], run: fetchUserAccess } = useRequest<
    BaseResponse<AccessItem[]>
  >(getBasicMenuAccount, { manual: true });

  return {
    info,
    fetchUserInfo,
    isFirstLogin,
    fetchUserIsFirstLogin,
    enterpriseInfo,
    fetchEnterpriseInfo,
    access,
    fetchUserAccess,
  };
}
