import approveRoutes from './modules/approve';
import attendanceRoutes from './modules/attendance';
import deviceRoutes from './modules/device';
import employeeRoutes from './modules/employee';
import homeRoutes from './modules/home';
import laborRoutes from './modules/labor';
import recruitRoutes from './modules/recruit';
import settingRoutes from './modules/setting';
import settlementRoutes from './modules/settlement';
import statementRoutes from './modules/statement';

import type { Route } from '@/interfaces/route';

const routes: Route[] = [
  // { path: '/', exact: true, redirect: '/home' },
  {
    path: '/user',
    component: '@/layouts/LoginLayout',
    routes: [
      {
        path: 'login',
        component: '@/pages/user/login',
        name: '登录',
      },
      {
        path: 'password',
        component: '@/pages/user/password',
        name: '找回密码',
      },
      {
        path: '/user',
        exact: true,
        redirect: '/user/login',
      },
    ],
  },
  {
    path: '/',
    component: '@/layouts/BasicLayout',
    wrappers: [
      '@/wrappers/SecurityWrapper',
      '@/wrappers/RedirectWrapper',
      '@/wrappers/AccessWrapper',
    ],
    routes: [
      homeRoutes,
      recruitRoutes,
      attendanceRoutes,
      employeeRoutes,
      deviceRoutes,
      ...settlementRoutes,
      statementRoutes,
      ...laborRoutes,
      ...approveRoutes,
      settingRoutes,
    ],
  },
  {
    path: '/403',
    component: '@/pages/error/403',
  },
];

export default routes;
