export default [
  {
    path: 'settlement',
    component: '@/pages/settlement',
    name: '结算管理',
    icon: 'PurseFilled',
    accessKey: '15',
  },
  {
    path: 'settlement/detail',
    component: '@/pages/settlement/detail',
    name: '结算详情',
    hideInMenu: true,
    selectedKey: '/settlement',
  },
  {
    path: 'settlement/recharge',
    component: '@/pages/settlement/recharge',
    name: '充值',
    hideInMenu: true,
    selectedKey: '/settlement',
  },
  {
    path: 'settlement/record',
    component: '@/pages/settlement/record',
    name: '明细',
    hideInMenu: true,
    selectedKey: '/settlement',
  },
];
