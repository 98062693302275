module.exports = {
  "name": "hotel-web",
  "private": true,
  "scripts": {
    "analyze": "ANALYZE=1 max build",
    "build": "max build",
    "build:pre": "cross-env UMI_ENV=pre max build",
    "build:production": "cross-env UMI_ENV=production max build",
    "build:release": "cross-env UMI_ENV=release max build",
    "build:test": "cross-env UMI_ENV=test max build",
    "lint": "tsc --noemit && eslint 'src/**/*.{js,jsx,tsx,ts}' --quiet",
    "lint:fix": "tsc --noemit && eslint 'src/**/*.{js,jsx,tsx,ts}' --fix --quiet",
    "prepare": "husky install",
    "prettier": "prettier --write '**/*.{less,css,md,json}'",
    "start": "max dev",
    "test": "umi-test",
    "test:coverage": "umi-test --coverage"
  },
  "lint-staged": {
    "*.{js,jsx,ts,tsx}": [
      "npm run lint --",
      "git add ."
    ],
    "*.{less,css,md,json}": [
      "npm run prettier --",
      "git add ."
    ]
  },
  "dependencies": {
    "@amap/amap-jsapi-loader": "^1.0.1",
    "@ant-design/plots": "^1.2.5",
    "@pangu/icons-react": "^1.6.0",
    "@pangu/materials": "^1.13.10",
    "@pangu/utils": "^2.1.0",
    "ahooks": "^3.7.8",
    "antd": "^5.20.2",
    "big.js": "^6.2.1",
    "classnames": "^2.3.2",
    "crypto-js": "^4.2.0",
    "dayjs": "^1.11.10",
    "js-cookie": "^3.0.5",
    "lodash": "^4.17.21",
    "react": "^18.2.0",
    "react-dom": "^18.2.0",
    "umi-request": "^1.4.0"
  },
  "devDependencies": {
    "@amap/amap-jsapi-types": "^0.0.13",
    "@pangu/end-type-to-front-type": "^1.0.0",
    "@pangu/lint": "^2.0.0",
    "@types/big.js": "^6.2.2",
    "@types/classnames": "^2.3.1",
    "@types/crypto-js": "^4.1.3",
    "@types/js-cookie": "^3.0.5",
    "@types/lodash": "^4.14.200",
    "@types/react": "^18.2.32",
    "@types/react-dom": "^18.2.14",
    "@umijs/max": "^4.3.12",
    "babel-plugin-transform-remove-console": "^6.9.4",
    "cross-env": "^7.0.3",
    "eslint": "^8.52.0",
    "husky": "^8.0.3",
    "lint-staged": "^13.3.0",
    "prettier": "^3.0.3",
    "typescript": "^5.2.2"
  }
}
