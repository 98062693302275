export default {
  path: 'employee',
  name: '职工考勤',
  icon: 'WorkingHoursFilled',
  accessKey: '26',
  routes: [
    {
      path: 'list',
      component: '@/pages/employee/list',
      name: '职工管理',
      accessKey: '27',
    },
    {
      path: 'create',
      component: '@/pages/employee/list/create',
      name: '新增人员',
      hideInMenu: true,
      selectedKey: '/employee/list',
    },
    {
      path: 'edit',
      component: '@/pages/employee/list/create',
      name: '编辑人员',
      hideInMenu: true,
      selectedKey: '/employee/list',
    },
    {
      path: 'detail',
      component: '@/pages/employee/list/detail',
      name: '人员详情',
      hideInMenu: true,
      selectedKey: '/employee/list',
    },
    {
      path: 'plan',
      component: '@/pages/employee/plan',
      name: '考勤排班',
      accessKey: '28',
    },
    {
      path: 'plan/create',
      component: '@/pages/employee/plan/create',
      name: '新增考勤排班',
      hideInMenu: true,
      selectedKey: '/employee/plan',
    },
    {
      path: 'plan/edit',
      component: '@/pages/employee/plan/create',
      name: '编辑考勤排班',
      hideInMenu: true,
      selectedKey: '/employee/plan',
    },
    {
      path: 'scheduling',
      component: '@/pages/employee/scheduling',
      name: '班次管理',
      accessKey: '29',
    },
    {
      path: 'holiday',
      component: '@/pages/employee/holiday',
      name: '节假日管理',
      accessKey: '30',
    },
    {
      path: 'day',
      component: '@/pages/employee/day',
      name: '每日考勤',
      accessKey: '31',
    },
    {
      path: 'day/record',
      component: '@/pages/employee/day/record',
      name: '补卡记录',
      hideInMenu: true,
      selectedKey: '/employee/day',
    },
    {
      path: 'month',
      component: '@/pages/employee/month',
      name: '月度汇总',
      accessKey: '32',
    },
    {
      path: 'month/detail',
      component: '@/pages/employee/month/detail',
      name: '详情',
      hideInMenu: true,
      selectedKey: '/employee/month',
    },
    {
      path: 'record',
      component: '@/pages/employee/record',
      name: '打卡记录',
      accessKey: '33',
    },
  ],
};
