export default {
  path: 'statement',
  name: '报表统计',
  icon: 'OrderFilled',
  accessKey: '37',
  routes: [
    {
      path: 'month',
      component: '@/pages/statement/month',
      name: '月统计',
      accessKey: '38',
    },
  ],
};
