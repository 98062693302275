export default {
  path: 'device',
  name: '设备管理',
  icon: 'ConstructionSiteEquipmentFilled',
  accessKey: '12',
  routes: [
    {
      path: 'list',
      component: '@/pages/device/list',
      name: '设备管理',
      accessKey: '13',
    },
    {
      path: 'authorization',
      component: '@/pages/device/authorization',
      name: '授权管理',
      accessKey: '14',
    },
  ],
};
