export default {
  path: 'recruit',
  name: '用工管理',
  icon: 'PangZhanFilled',
  accessKey: '2',
  routes: [
    {
      path: 'plan',
      component: '@/pages/recruit/plan',
      name: '用工计划',
      accessKey: '3',
    },
    {
      path: 'plan/create',
      component: '@/pages/recruit/plan/create',
      name: '创建用工计划',
      hideInMenu: true,
      selectedKey: '/recruit/plan',
    },
    {
      path: 'plan/edit',
      component: '@/pages/recruit/plan/create',
      name: '编辑用工计划',
      hideInMenu: true,
      selectedKey: '/recruit/plan',
    },
    {
      path: 'plan/detail',
      component: '@/pages/recruit/plan/detail',
      name: '详情',
      hideInMenu: true,
      selectedKey: '/recruit/plan',
    },
    {
      path: 'room',
      component: '@/pages/recruit/room',
      name: '客房派单',
      accessKey: '36',
    },
    {
      path: 'room/create',
      component: '@/pages/recruit/room/create',
      name: '创建客房订单',
      hideInMenu: true,
      selectedKey: '/recruit/room',
    },
    {
      path: 'room/edit',
      component: '@/pages/recruit/room/create',
      name: '编辑客房订单',
      hideInMenu: true,
      selectedKey: '/recruit/room',
    },
    {
      path: 'room/detail',
      component: '@/pages/recruit/room/detail',
      name: '客房订单详情',
      hideInMenu: true,
      selectedKey: '/recruit/room',
    },
    {
      path: 'flexible',
      component: '@/pages/recruit/flexible',
      name: '灵工管理',
      accessKey: '4',
    },
    {
      path: 'flexible/detail',
      component: '@/pages/recruit/flexible/detail',
      name: '详情',
      hideInMenu: true,
      selectedKey: '/recruit/flexible',
    },
    {
      path: 'post',
      component: '@/pages/recruit/post',
      name: '岗位管理',
      accessKey: '5',
    },
  ],
};
