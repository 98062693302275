export default {
  path: 'setting',
  name: '设置',
  icon: 'SetUpFilled',
  accessKey: '19',
  routes: [
    {
      path: 'account',
      component: '@/pages/setting/account',
      name: '账户管理',
      accessKey: '20',
    },
    {
      path: 'base',
      component: '@/pages/setting/base',
      name: '基本信息',
      accessKey: '21',
    },
    {
      path: 'role',
      component: '@/pages/setting/role',
      name: '角色管理',
      accessKey: '22',
    },

    {
      path: 'approve',
      component: '@/pages/setting/approve',
      name: '审批设置',
      accessKey: '23',
    },

    {
      path: 'log',
      component: '@/pages/setting/log',
      name: '操作日志',
      accessKey: '24',
    },
    {
      path: 'other',
      component: '@/pages/setting/other',
      name: '其他配置',
      accessKey: '25',
    },
  ],
};
