// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import model_1 from '/var/lib/jenkins/workspace/BLUE-PROD_hotel-web/src/models/global';
import model_2 from '/var/lib/jenkins/workspace/BLUE-PROD_hotel-web/src/models/user';
import model_3 from '/var/lib/jenkins/workspace/BLUE-PROD_hotel-web/src/.umi-production/plugin-initialState/@@initialState';

export const models = {
model_1: { namespace: 'global', model: model_1 },
model_2: { namespace: 'user', model: model_2 },
model_3: { namespace: '@@initialState', model: model_3 },
} as const
