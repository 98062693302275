export default [
  {
    path: 'labor',
    name: '劳务管理',
    icon: 'CustomFilled',
    accessKey: '16',
    routes: [
      {
        path: 'company',
        component: '@/pages/labor/company',
        name: '公司管理',
        accessKey: '34',
      },
      {
        path: 'pool',
        component: '@/pages/labor/pool',
        name: '劳务公司库',
        accessKey: '35',
      },
    ],
  },
];
