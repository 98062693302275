export default [
  {
    path: 'approve/list',
    component: '@/pages/approve/list',
    name: '审批管理',
    icon: 'ApproverFilled',
    accessKey: '17',
  },
  {
    path: 'approve/detail',
    component: '@/pages/approve/detail',
    name: '审批详情',
    hideInMenu: true,
    selectedKey: '/approve/list',
  },
  {
    path: 'approve/create',
    component: '@/pages/approve/create',
    name: '发起用工审批',
    hideInMenu: true,
    selectedKey: '/approve/list',
  },
];
